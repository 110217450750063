import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import hamburger from "../assets/hamburger.png";

function Navbar({ width, showMobileNav, setShowMobileNav }) {
  const { pathname } = useLocation();

  function handleHamburgerClick(event) {
    event.preventDefault();
    setShowMobileNav(!showMobileNav);
  }

  useEffect(() => {
    console.log("showMobileNav: ", showMobileNav);
  }, [showMobileNav, pathname]);

  if (width >= 1280) {
    return (
      <nav className="container-fluid d-flex justify-content-center nav-container">
        <div className="row nav-row">
          <div className="col-9">
            <Link to="/" className="nav-name nav-name-hover-underline">
              Kevin Bisner
            </Link>

            <div className="nav-job-title">Full-Stack Developer</div>
          </div>
          <div className="col-3 d-flex justify-content-around align-items-center">
            <Link to="/about" className="navbar-link nav-link-hover-underline">
              About
            </Link>
            <Link
              to="/contact"
              className="navbar-link nav-link-hover-underline"
            >
              Contact
            </Link>
          </div>
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="container-fluid nav-container">
        <div className="row nav-row">
          <div className="col-10">
            <Link to="/" className="nav-name nav-name-hover-underline">
              Kevin Bisner
            </Link>

            <div className="nav-job-title">Full-Stack Developer</div>
          </div>
          <div className="col-1 offset-1 d-flex flex-column justify-content-around align-items-center">
            <img
              src={hamburger}
              className="nav-icon"
              onClick={handleHamburgerClick}
              alt="hamburger menu icon"
            />
            <div
              className="menu-items"
              style={showMobileNav ? { display: "flex" } : { display: "none" }}
            >
              <Link
                to="/about"
                className="navbar-link nav-link-hover-underline"
              >
                About
              </Link>
              <Link
                to="/contact"
                className="navbar-link nav-link-hover-underline"
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
