import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./projectLinks.css";
import decoderRingScreen from "../assets/decoder_ring_screen.png";
import flashcardScreen from "../assets/flashcard_screen.png";
import grubdashScreen from "../assets/grubdash_screen.png";
import welovemoviesScreen from "../assets/welovemovies_screen.png";
import pomodoroScreen from "../assets/pomodoro_screen.png";

function ProjectLinks() {
  const { pathname } = useLocation();
  const projectName = pathname.slice(10);
  console.log("projectName: ", projectName);

  const hasUpdatedHotLink = useRef(false);
  const [hotLink, setHotLink] = useState({
    welovemovies: false,
    flashomatic: false,
    grubdash: false,
    pomodoro: false,
    decoderring: false,
  });

  useEffect(() => {
    if (!hasUpdatedHotLink) {
      setHotLink({
        welovemovies: false,
        flashomatic: false,
        grubdash: false,
        pomodoro: false,
        decoderring: false,
        [projectName]: !hotLink.projectName,
      });
      hasUpdatedHotLink.current = true;
    }
  }, [projectName, hotLink]);

  return (
    <div className="container-fluid projects-container">
      <Link
        to="/projects/welovemovies"
        className="row project-item d-flex flex-column align-items-center justify-content-evenly text-decoration-none"
        style={
          hotLink.welovemovies
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div name="welovemovies">
          <img src={welovemoviesScreen} alt="profile" className="project-img" />
          <div className="text-decoration-none project-title d-flex justify-content-start align-items-center mt-2">
            <span className="oi oi-arrow-left leftArrow"></span>
            <div className="project-title-text">We Love Movies</div>
          </div>
        </div>
      </Link>
      <Link
        to="/projects/flashomatic"
        className="row project-item d-flex flex-column align-items-center justify-content-evenly text-decoration-none"
        style={
          hotLink.flashomatic
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div name="flashomatic">
          <img src={flashcardScreen} alt="profile" className="project-img" />
          <div className="text-decoration-none project-title d-flex justify-content-start align-items-center mt-2">
            <span className="oi oi-arrow-left leftArrow"></span>
            <div className="project-title-text">Flash-o-matic</div>
          </div>
        </div>
      </Link>
      <Link
        to="/projects/grubdash"
        className="row project-item d-flex flex-column align-items-center justify-content-evenly text-decoration-none"
        style={
          hotLink.grubdash
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div name="grubdash">
          <img src={grubdashScreen} alt="profile" className="project-img" />
          <div className="text-decoration-none project-title d-flex justify-content-start align-items-center mt-2">
            <span className="oi oi-arrow-left leftArrow"></span>
            <div className="project-title-text">GrubDash</div>
          </div>
        </div>
      </Link>
      <Link
        to="/projects/pomodoro"
        className="row project-item d-flex flex-column align-items-center justify-content-evenly text-decoration-none"
        style={
          hotLink.pomodoro
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div name="grubdash">
          <img src={pomodoroScreen} alt="profile" className="project-img" />
          <div className="text-decoration-none project-title d-flex justify-content-start align-items-center mt-2">
            <span className="oi oi-arrow-left leftArrow"></span>
            <div className="project-title-text">Pomodoro Timer</div>
          </div>
        </div>
      </Link>
      <Link
        to="/projects/decoderring"
        className="row project-item d-flex flex-column align-items-center justify-content-evenly text-decoration-none"
        style={
          hotLink.decoderring
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div name="decoderring">
          <img src={decoderRingScreen} alt="profile" className="project-img" />
          <div className="text-decoration-none project-title d-flex justify-content-start align-items-center mt-2">
            <span className="oi oi-arrow-left leftArrow"></span>
            <div className="project-title-text">Decoder Ring</div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProjectLinks;
