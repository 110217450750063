import React from "react";

function FlashomaticMobile() {
  return (
    <div className="project-description-mobile">
      <h3 className="project-item-header-mobile">
        Make studying easy with homemade flashcards
      </h3>
      <div className="project-item-description-mobile">
        <p>
          Flash-o-matic is the easiest way to create your own flashcards to
          study any subject or topic. You can easily create, edit, and delete
          full decks and specific cards. You also have the option to study a
          deck that you have created.
        </p>

        <p>
          The application uses JavaScript, React, HTML5 & CSS3. Check out my
          code for this application on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/flashcard-app"
            target="_blank"
            className="project-link-hover-underline-mobile"
            rel="noreferrer"
          >
            Flash-o-matic code
          </a>
        </p>
      </div>
    </div>
  );
}

export default FlashomaticMobile;
