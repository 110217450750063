import React from "react";
import { Link } from "react-router-dom";
import "./thankyou.css";

function ThankYou() {
  return (
    <div className="container-fluid thankyou-container">
      <div>Thank you for your message. I'll reply as soon as possible.</div>
      <div>
        Head back over to my{" "}
        <Link to="/" className="thankyou-link">
          home page
        </Link>{" "}
        and check out some of my projects.
      </div>
    </div>
  );
}

export default ThankYou;
