import React from "react";
import { Link } from "react-router-dom";
import "./about.css";
import kevWedding from "../assets/kevin_singing_wedding.png";
import githubLogo from "../assets/github_logo.png";
import twitterLogo from "../assets/twitter_logo.png";
import linkedinLogo from "../assets/linkedin_logo.png";

function About() {
  return (
    <div className="container-fluid about-container">
      <div className="row about-content">
        <div className="about-content-image">
          <img
            src={kevWedding}
            alt="kevin singing at his wedding"
            className="about-main-image"
          />
        </div>
        <div className="about-text-content">
          <h2 className="about-title text-center">I'm Kevin!</h2>
          <div className="about-text">
            <p>
              I have a puppy and a hubby.
              <br />
              I rock at karaoke and love to sing!
              <br />
              I don’t really cook much, but I make a great baked ziti.
              <br />
              I’m passionate about coding.
              <br />
              I have a Master’s degree in Communication.
              <br />
              <Link to="/contact" className="text-decoration-none">
                <span className="contact-link-hover-underline">Contact me</span>{" "}
              </Link>
              and let’s talk about whatever!
            </p>
            <div className="row social-row">
              <a
                href="https://github.com/KBiz65"
                target="_blank"
                className="col-2 social-link"
                rel="noreferrer"
              >
                <img
                  src={githubLogo}
                  className="social-logo"
                  alt="github logo"
                />
              </a>
              <a
                href="https://twitter.com/KevinBisner"
                target="_blank"
                className="col-2 social-link"
                rel="noreferrer"
              >
                <img
                  src={twitterLogo}
                  className="social-logo"
                  alt="twitter logo"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/kevinbisner/"
                target="_blank"
                className="col-2 social-link"
                rel="noreferrer"
              >
                <img
                  src={linkedinLogo}
                  className="social-logo"
                  alt="linkedIn logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
