import React from "react";
import { useParams } from "react-router-dom";
import Welovemovies from "../Projects/Welovemovies";
import Flashomatic from "../Projects/Flashomatic";
import Grubdash from "../Projects/Grubdash";
import Pomodoro from "../Projects/Pomodoro";
import DecoderRing from "../Projects/DecoderRing";

function Project() {
  const { projectName } = useParams();

  switch (projectName) {
    case "welovemovies":
      return <Welovemovies />;
    case "flashomatic":
      return <Flashomatic />;
    case "grubdash":
      return <Grubdash />;
    case "pomodoro":
      return <Pomodoro />;
    case "decoderring":
      return <DecoderRing />;
    default:
      break;
  }
}

export default Project;
