import React from "react";

function PomodoroMobile() {
  return (
    <div className="project-description-mobile">
      <h3 className="project-item-header-mobile">
        Focus on tasks better with a Pomodoro timer
      </h3>
      <div className="project-item-description-mobile">
        <p>
          My Pomodoro timer helps you focus by setting intervals for focusing on
          a given task. Simply set the timer to your desired time to focus, set
          the break interval, and you can stay focused on the task at hand. An
          audible tone will let you know it's time to move on to your next stage
          of focusing or stepping away.
        </p>
        <p>
          The application was created with JavaScript, React, and Bootstrap.
          Take a look at the code for this application on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/pomodoro-timer"
            target="_blank"
            className="project-link-hover-underline-mobile"
            rel="noreferrer"
          >
            Pomodoro Timer code!
          </a>
        </p>
      </div>
    </div>
  );
}

export default PomodoroMobile;
