import React, { useState } from "react";
import "./HomepageMobile.css";
import decoderRingScreen from "../assets/decoder_ring_screen.png";
import flashcardScreen from "../assets/flashcard_screen.png";
import grubdashScreen from "../assets/grubdash_screen.png";
import welovemoviesImage from "../assets/welovemovies_screen.png";
import pomodoroScreen from "../assets/pomodoro_screen.png";
import WelovemoviesMobile from "./WelovemoviesMobile";
import FlashomaticMobile from "./FlashomaticMobile";
import GrubdashMobile from "./GrubdashMobile";
import PomodoroMobile from "./PomodoroMobile";
import DecoderringMobile from "./DecoderringMobile";

function HomepageMobile() {
  const [hotLink, setHotLink] = useState();

  function handleProjectClick(projectName) {
    if (!hotLink || hotLink !== projectName) {
      setHotLink(projectName);
    } else {
      setHotLink();
    }
  }

  return (
    <div className="homepage-container-mobile">
      <div>
        <h2 className="homepage-welcome-text-mobile">Hey folks! 👋</h2>
      </div>
      <div>
        <h2 className="homepage-statement-text-mobile">
          I'm a Full Stack Developer.
        </h2>
      </div>
      <div>
        <div className="homepage-text-mobile">
          <p>
            I enjoy making creative, elegant, and simple web applications.
            <br /> I love seeing a client's face light up seeing their new
            website or software.
            <br /> I thrive building a robust backend.
          </p>
          <br />
          <p>Contact me to make something mind-blowing together!</p>
        </div>
      </div>
      {/* *********************** PROJECTS START HERE *********************** */}
      {/* *********************** WE LOVE MOVIES *********************** */}
      <div
        className="project-item-mobile"
        onClick={() => handleProjectClick("welovemovies")}
        style={
          hotLink === "welovemovies"
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div className="project-content-mobile">
          <img
            src={welovemoviesImage}
            alt="welovemovies screenshot"
            className="project-img-mobile"
          />
          <div className="project-title-mobile">
            {hotLink === "welovemovies" ? (
              <span className="oi oi-arrow-top arrow"></span>
            ) : (
              <span className="oi oi-arrow-bottom arrow"></span>
            )}

            <div className="project-title-text-mobile">We Love Movies</div>
          </div>
        </div>
        {hotLink === "welovemovies" ? <WelovemoviesMobile /> : null}
      </div>
      {/* *********************** FLASH-O-MATIC *********************** */}
      <div
        className="project-item-mobile"
        onClick={() => handleProjectClick("flashomatic")}
        style={
          hotLink === "flashomatic"
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div className="project-content-mobile">
          <img
            src={flashcardScreen}
            alt="flashomatic screenshot"
            className="project-img-mobile"
          />
          <div className="project-title-mobile">
            {hotLink === "flashomatic" ? (
              <span className="oi oi-arrow-top arrow"></span>
            ) : (
              <span className="oi oi-arrow-bottom arrow"></span>
            )}

            <div className="project-title-text-mobile">Flash-O-Matic</div>
          </div>
        </div>
        {hotLink === "flashomatic" ? <FlashomaticMobile /> : null}
      </div>
      {/* *********************** GRUBDASH *********************** */}
      <div
        className="project-item-mobile"
        onClick={() => handleProjectClick("grubdash")}
        style={
          hotLink === "grubdash"
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div className="project-content-mobile">
          <img
            src={grubdashScreen}
            alt="grubdash screenshot"
            className="project-img"
          />
          <div className="project-title-mobile">
            {hotLink === "grubdash" ? (
              <span className="oi oi-arrow-top arrow"></span>
            ) : (
              <span className="oi oi-arrow-bottom arrow"></span>
            )}

            <div className="project-title-text-mobile">GrubDash</div>
          </div>
        </div>
        {hotLink === "grubdash" ? <GrubdashMobile /> : null}
      </div>
      {/* *********************** POMODORO TIMER *********************** */}
      <div
        className="project-item-mobile"
        onClick={() => handleProjectClick("pomodoro")}
        style={
          hotLink === "pomodoro"
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div className="project-content-mobile">
          <img
            src={pomodoroScreen}
            alt="pomodoro screenshot"
            className="project-img"
          />
          <div className="project-title-mobile">
            {hotLink === "pomodoro" ? (
              <span className="oi oi-arrow-top arrow"></span>
            ) : (
              <span className="oi oi-arrow-bottom arrow"></span>
            )}

            <div className="project-title-text-mobile">Pomodoro Timer</div>
          </div>
        </div>
        {hotLink === "pomodoro" ? <PomodoroMobile /> : null}
      </div>
      {/* *********************** DECODER RING *********************** */}
      <div
        className="project-item-mobile"
        onClick={() => handleProjectClick("decoderring")}
        style={
          hotLink === "decoderring"
            ? { backgroundColor: "#8a3a46", color: "#FFFFFF" }
            : null
        }
      >
        <div className="project-content-mobile">
          <img
            src={decoderRingScreen}
            alt="decoder ring screenshot"
            className="project-img-mobile"
          />
          <div className="project-title-mobile">
            {hotLink === "decoderring" ? (
              <span className="oi oi-arrow-top arrow"></span>
            ) : (
              <span className="oi oi-arrow-bottom arrow"></span>
            )}

            <div className="project-title-text-mobile">Decoder Ring</div>
          </div>
        </div>
      </div>
      {hotLink === "decoderring" ? <DecoderringMobile /> : null}
    </div>
  );
}

export default HomepageMobile;
