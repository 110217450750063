import React from "react";
import "./decoderring.css";
import decoderRingScreenLarge from "../assets/decoder_ring_screen_large.png";

function DecoderRing() {
  return (
    <div className="container-fluid project-item-container d-flex flex-column align-items-center">
      <h2 className="project-title text-center">Decoder Ring!</h2>
      <img
        src={decoderRingScreenLarge}
        alt="screenshot of welovemovies app on computer screen"
        className="project-item-main-image"
      />
      <h3 className="project-item-header">
        Encrypt and decrypt secret messages!
      </h3>
      <div className="project-item-description">
        <p>
          This was a super fun project to work on. The Decoder Ring app lets you
          encrypt or decrypt messages three different ways: Caesar shift,
          Polybius Square, or with a substitution key.
        </p>
        <p>
          The application was created with JavaScript and Bootstrap. My job was
          to create the algorithms for each of the encryption types. Take a look
          at the code for my Decoder Ring on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/decoder-ring"
            target="_blank"
            className="project-link-hover-underline"
            rel="noreferrer"
          >
            Decoder Ring code!
          </a>
        </p>
      </div>
    </div>
  );
}

export default DecoderRing;
