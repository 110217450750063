import React from "react";
import "./welovemovies.css";
import welovemoviesScreenLarge from "../assets/welovemovies_screen_large.png";

function Welovemovies() {
  return (
    <div className="container-fluid project-item-container d-flex flex-column align-items-center">
      <h2 className="project-title text-center">WeLoveMovies!</h2>
      <img
        src={welovemoviesScreenLarge}
        alt="screenshot of welovemovies app on computer screen"
        className="project-item-main-image"
      />
      <h3 className="project-item-header">
        Find your favorite movies at your local theater
      </h3>
      <div className="project-item-description">
        <p>
          The WeLoveMovies app allows the user to see a list of movies and
          theaters. This application works by connecting to a database that has
          been loaded with some fake data.
        </p>
        <p>
          In live production it would connect to a movie theater's database to
          query information from it directly.
        </p>
        <p>
          The application uses JavaScript, React, NodeJS, Express, PostgreSQL
          and Knex. The front-end was deployed on Vercel with the back-end
          deployed on Heroku. Check out a live demo of the application here:{" "}
          <a
            href="https://kbiz-movies-front-end.vercel.app/"
            target="_blank"
            className="project-link-hover-underline"
            rel="noreferrer"
          >
            WeLoveMovies!
          </a>
        </p>
        <p>
          You can see the back-end code here:{" "}
          <a
            href="https://github.com/KBiz65/we-love-movies-back-end"
            target="_blank"
            className="project-link-hover-underline"
            rel="noreferrer"
          >
            WeLoveMovies back-end code
          </a>
        </p>
        <p>
          You can see the front-end code here:{" "}
          <a
            href="https://github.com/KBiz65/we-love-movies-front-end"
            target="_blank"
            className="project-link-hover-underline"
            rel="noreferrer"
          >
            WeLoveMovies front-end code
          </a>
        </p>
      </div>
    </div>
  );
}

export default Welovemovies;
