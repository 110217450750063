import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Featured from "./Featured/Featured";
import ProjectLinks from "./ProjectLinks/ProjectLinks";
import HomepageMobile from "./Mobile/HomepageMobile";
import About from "./About/About";
import Contact from "./Contact/Contact";
import ThankYou from "./Contact/ThankYou";
import Error from "./Contact/Error";
import "./app.css";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [showMobileNav, setShowMobileNav] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      console.log("width: ", width);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    setShowMobileNav(false);
  }, [pathname]);

  const renderHomepage = <HomepageMobile />;
  const renderAbout = <About />;
  const renderContact = <Contact />;
  const renderThankyou = <ThankYou />;
  const renderError = <Error />;

  if (width >= 1280) {
    return (
      <div className="App">
        <div className="container-fluid navbar-container">
          <Navbar width={width} />
        </div>
        <div className="container-fluid home-container">
          <div className="row featured-projects-row">
            <div className="col-9 featured-col d-flex flex-column justify-content-start">
              <Featured />
            </div>
            <div className="col-3 projects-col d-flex flex-column">
              <ProjectLinks />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Navbar
          width={width}
          showMobileNav={showMobileNav}
          setShowMobileNav={setShowMobileNav}
        />
        {pathname === "/" ? renderHomepage : null}
        {pathname === "/about" ? renderAbout : null}
        {pathname === "/contact" ? renderContact : null}
        {pathname === "/thankyou" ? renderThankyou : null}
        {pathname === "/error" ? renderError : null}
      </div>
    );
  }
}

export default App;
