import React from "react";
import "./flashomatic.css";
import flashcard_screen_large from "../assets/flashcard_screen_large.png";

function Flashomatic() {
  return (
    <div className="container-fluid project-item-container d-flex flex-column align-items-center">
      <h2 className="project-title text-center">Flash-o-matic!</h2>
      <img
        src={flashcard_screen_large}
        alt="screenshot of flash-o-matic app on computer screen"
        className="project-item-main-image"
      />
      <h3 className="project-item-header">
        Make studying easy with homemade flashcards
      </h3>
      <div className="project-item-description">
        <p>
          Flash-o-matic is the easiest way to create your own flashcards to
          study any subject or topic. You can easily create, edit, and delete
          full decks and specific cards. You also have the option to study a
          deck that you have created.
        </p>

        <p>
          The application uses JavaScript, React, HTML5 & CSS3. Check out my
          code for this application on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/flashcard-app"
            target="_blank"
            className="project-link-hover-underline"
            rel="noreferrer"
          >
            Flash-o-matic code
          </a>
        </p>
      </div>
    </div>
  );
}

export default Flashomatic;
