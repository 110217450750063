import React from "react";
import { Switch, Route } from "react-router-dom";
import Homepage from "../Homepage/Homepage";
import About from "../About/About";
import Contact from "../Contact/Contact";
import Project from "../Project/Project";
import ThankYou from "../Contact/ThankYou";
import Error from "../Contact/Error";
import "./featured.css";

function Featured() {
  return (
    <div className="container-fluid featured-container d-flex flex-column justify-content-start align-items-center">
      <div className="row w-100 d-flex flex-column">
        <div className="col featured-header">
          <Switch>
            <Route path="/projects/:projectName">
              <Project />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/thankyou">
              <ThankYou />
            </Route>
            <Route path="/error">
              <Error />
            </Route>
            <Route path="/">
              <Homepage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Featured;
