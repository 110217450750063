import React from "react";

function DecoderringMobile() {
  return (
    <div className="project-description-mobile">
      <h3 className="project-item-header-mobile">
        Encrypt and decrypt secret messages!
      </h3>
      <div className="project-item-description-mobile">
        <p>
          This was a super fun project to work on. The Decoder Ring app lets you
          encrypt or decrypt messages three different ways: Caesar shift,
          Polybius Square, or with a substitution key.
        </p>
        <p>
          The application was created with JavaScript and Bootstrap. My job was
          to create the algorithms for each of the encryption types. Take a look
          at the code for my Decoder Ring on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/decoder-ring"
            target="_blank"
            className="project-link-hover-underline-mobile"
            rel="noreferrer"
          >
            Decoder Ring code!
          </a>
        </p>
      </div>
    </div>
  );
}

export default DecoderringMobile;
