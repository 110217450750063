import React from "react";
import codeCircle from "../assets/code_circle.png";
import cssCircle from "../assets/css_circle.png";
import expressCircle from "../assets/express_circle.png";
import htmlCircle from "../assets/html_circle.png";
import javascriptCircle from "../assets/javascript_circle.png";
import knexCircle from "../assets/knex_circle.png";
import nodejsCircle from "../assets/nodejs_circle.png";
import postgresqlCircle from "../assets/postgresql_circle.png";
import pythonCircle from "../assets/python_circle.png";
import reactCircle from "../assets/react_circle.png";
import "./homepage.css";

function Homepage() {
  return (
    <div className="container-fluid homepage-container">
      <img
        src={pythonCircle}
        alt="python logo"
        className="medium-logo python-logo"
      />
      <img
        src={nodejsCircle}
        alt="node js logo"
        className="large-logo nodejs-logo"
      />
      <img src={cssCircle} alt="css logo" className="small-logo css-logo" />
      <img
        src={reactCircle}
        alt="react logo"
        className="large-logo react-logo"
      />
      <img
        src={postgresqlCircle}
        alt="postgresql logo"
        className="medium-logo postgresql-logo"
      />
      <img src={codeCircle} alt="code tag" className="small-logo code-logo" />
      <img
        src={expressCircle}
        alt="node express logo"
        className="large-logo express-logo"
      />
      <img
        src={htmlCircle}
        alt="html 5 logo"
        className="small-logo html-logo"
      />
      <img
        src={javascriptCircle}
        alt="javascript logo"
        className="large-logo javascript-logo"
      />
      <img
        src={knexCircle}
        alt="python logo"
        className="small-logo knex-logo"
      />

      <div>
        <h2 className="text-start homepage-welcome-text">Hey folks! 👋</h2>
      </div>
      <div>
        <h2 className="text-start homepage-statement-text">
          I'm a Full Stack Developer.
        </h2>
      </div>
      <div>
        <div className="homepage-text">
          <p>
            I enjoy making creative, elegant, and simple web applications.
            <br /> I love seeing a client's face light up seeing their new
            website or software.
            <br /> I thrive building a robust backend.
          </p>
          <br />
          <p>Contact me to make something mind-blowing together!</p>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
