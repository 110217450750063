import React from "react";

function GrubdashMobile() {
  return (
    <div className="project-description-mobile">
      <h3 className="project-item-header-mobile">
        Get your favorite foods delivered to your door.
      </h3>
      <div className="project-item-description-mobile">
        <p>
          GrubDash is mock website based on popular food delivery services. The
          purpose for creating the app was to practice working with router and
          controller files to route different requests to the proper paths.
        </p>
        <p>
          In live production this app would be connected to a database to
          fulfill the proper requests from the client.
        </p>
        <p>
          The application uses JavaScript, React, NodeJS, Express, and CORS.
          Take a look at the code for this application on GitHub:{" "}
          <a
            href="https://github.com/KBiz65/grubdash"
            target="_blank"
            className="project-link-hover-underline-mobile"
            rel="noreferrer"
          >
            GrubDash code
          </a>
        </p>
      </div>
    </div>
  );
}

export default GrubdashMobile;
