import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-google";
import "./contact.css";

function Contact() {
  const history = useHistory();
  console.log("history: ", history);

  useEffect(() => {
    loadReCaptcha();
  }, []);

  return (
    <div className="container-fluid contact-container">
      <div className="row contact-form w-100">
        <h2 className="contact-title text-center">Contact Me</h2>
        <div className="contact-description">
          Use the form below to reach out with any questions, comments,
          concerns, advice, or anything else you'd like to say. If you are
          looking to hire me or get a quote on a website, please mention that in
          your message.
        </div>
        <form
          action="https://submit-form.com/QfUpCB4S"
          method="POST"
          className="form-body"
        >
          <input
            type="hidden"
            name="_redirect"
            value="https://kevinbisner.com/thankyou"
          />

          <input
            type="hidden"
            name="_error"
            value="https://kevinbisner.com/error"
          />

          <input
            type="text"
            name="name"
            className="form-control"
            placeholder="Name"
            required=""
          />
          <input
            type="text"
            name="email"
            className="form-control"
            placeholder="Email"
            required=""
          />

          <textarea
            name="message"
            className="form-control"
            placeholder="Message"
            row="5"
            required=""
          ></textarea>

          <button type="submit">SEND MESSAGE</button>
          <div
            className="g-recaptcha"
            data-sitekey="6LciOi4cAAAAAD342hPa6VorB1udDSVr-UiajAuJ"
          ></div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
