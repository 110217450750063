import React from "react";
import { Link } from "react-router-dom";
import "./error.css";

function Error() {
  return (
    <div className="container-fluid error-container">
      <div>There was a problem with your submission.</div>
      <div>
        Head back over to my{" "}
        <Link to="/contact" className="error-link">
          contact page
        </Link>{" "}
        if you'd like to try again. Make sure to fill out all the fields.
      </div>
    </div>
  );
}

export default Error;
